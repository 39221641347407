import { makeStyles } from '@mui/styles'

export default makeStyles(theme => ({
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    zIndex: -1, // Place it behind the content
  },
  fade: {
    transition: 'opacity 1s ease-in-out',
  },
  spring: {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/spring.jpg'})`,
  },
  summer: {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/summer.jpg'})`,
  },
  autumn: {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/autumn.jpg'})`,
  },
  winter: {
    backgroundImage: `url(${process.env.PUBLIC_URL + '/winter.jpg'})`,
  },
  foreground: {
  marginTop: 30,
    position: 'relative', // To ensure the content is above the background
  },
  date: {
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    borderRadius: 7,
    alignItems: 'center',

  },
  marginTop30: {
    marginTop: 30
  },
  marginLeft20: {
    marginLeft: 20
  },
  executeBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 7,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    marginTop: 5,
    padding: 25,
    height: 120
  },
  unselectedImg: {
    '&:hover': {
      backgroundColor: 'rgba(230, 230, 230, 0.85)', // slightly less transparent on hover for a nice effect
    },
    borderRadius: 7
  },
  img: {
    backgroundColor: '#EEEEEE',
    borderRadius: 7
  },
  total: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    borderRadius: 7,
    padding: '1.5em 1em 1.5em 1em'
  },
  totalTokens: {
    display: 'flex',
    alignItems: 'center',
    height: 45
  },
  seasonalLogo: {
    width: 35,
    height:  35
  },
  totalValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modebtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 30
  },
  flex: {
    display: 'flex',
  },
  hr: {
    marginBottom: '2em',
    color: '#CCCCCC'
  },
  actions: {
    margin: '1em',
    borderRadius: 7,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
  },
  tradefor: {
    marginBottom: '1em',
    paddingLeft: '2em',
    paddingRight: '2em',
    borderRadius: 7,
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
  }
}))
