import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  button: {
    backgroundColor: 'rgba(255, 255, 255, 0.0)', // semi-transparent white background
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.75)', // slightly less transparent on hover for a nice effect
    },
  },
});

const ModeSwitching = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={onChange}
      aria-label="Platform"
    >
      <ToggleButton value={0} className={classes.button}>Beginner</ToggleButton>
      <ToggleButton value={2} className={classes.button}>Intermediate</ToggleButton>
      <ToggleButton value={1} className={classes.button}>Advanced</ToggleButton>
    </ToggleButtonGroup>
  );
}

export default ModeSwitching;
