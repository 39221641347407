import React, { useState, useEffect } from 'react';
import './BackgroundImages.css'; // Assuming you've named your CSS file as BackgroundImages.css

function BackgroundImageChanger({ activeIndex }) {
  return (
    <div className="background-wrapper">
      <div className="background-image" style={{ backgroundImage: 'url(/spring.jpg)' }} className={`background-image ${activeIndex === 0 ? 'active' : ''}`}></div>
      <div className="background-image" style={{ backgroundImage: 'url(/summer.jpg)' }} className={`background-image ${activeIndex === 1 ? 'active' : ''}`}></div>
      <div className="background-image" style={{ backgroundImage: 'url(/autumn.jpg)' }} className={`background-image ${activeIndex === 2 ? 'active' : ''}`}></div>
      <div className="background-image" style={{ backgroundImage: 'url(/winter.jpg)' }} className={`background-image ${activeIndex === 3 ? 'active' : ''}`}></div>
    </div>
  );
}

export default BackgroundImageChanger;
