import QRCode from 'qrcode.react';
import BackgroundImageChanger from './BackgroundImageChanger';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Container, Grid, Typography, Button, IconButton } from '@mui/material';
import {
  PlayCircleOutline as StartIcon,
  StopCircleOutlined as StopIcon,
  RefreshOutlined as RestartIcon
} from '@mui/icons-material'
import LogoFieldClickable from './components/LogoFieldClickable'
import GameAction from './components/GameAction'
import LogoField from './components/LogoField'
import Field from './components/Field'
import ChartsComp from './components/Charts'
import TransitionModal from './components/Modal/Modal'
import useStyles from './styles.js'
import ModeSwtiching from './components/ModeSwtiching';
import { getForBalance, getNormallyDistributedRandomNumber, SeedableRNG } from './utils';
import SeasonalLogo from './assets/Seasonal-Tokens-Logo.png'
import { ICON_SVG, INITIAL_DATE, TOKEN_ARRAY, STATUS, A_DAY, INITIAL_BALANCE, MODE } from './constants'

import axios from 'axios';

let prevRenderTime = Date.now()
const Charts = React.memo(ChartsComp, () => {
  const now = Date.now()
  if (now - prevRenderTime >= 500) {
    prevRenderTime = now
    return false
  } else {
    return true
  }
})


function App() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const params = new URLSearchParams(window.location.search);
  const platform = params.get('platform');
  const username = params.get('user');
  const userid = params.get('userid');
  const code = params.get('code');
  const mode_ = params.get('mode');
  const contestKey = params.get("contest_key")
  const isTask = params.get("task")
  const isDuel = params.get("duel")
  const [hiddenTime, setHiddenTime] = useState(() => new Date().getTime());
  const [seed, setSeed] = useState(() => (isDuel ? Math.round(hiddenTime/0x48353) : Number(userid)%0x458745) + Math.round(hiddenTime/0x48353));
  const [obscuredSeed, setObscuredSeed] = useState(() => seed);


  const [tradeHistory, setTradeHistory] = useState([]);
  const seasonAbbreviationMap = {
    WINTER: 'W',
    SPRING: 'Sp',
    SUMMER: 'Su',
    AUTUMN: 'A',
  };

  const getSeasonAbbreviation = (season) => {
    const upperCaseSeason = season.toUpperCase();
    return seasonAbbreviationMap[season] || season;
  };

  const timer = useRef()
  const classes = useStyles()
  const SEASONS = [
    { startDate: '2022-06-05', class: classes.summer, imageIndex: 1 },
    { startDate: '2023-03-06', class: classes.autumn, imageIndex: 2 },
    { startDate: '2023-12-05', class: classes.winter, imageIndex: 3 },
    { startDate: '2024-09-05', class: classes.spring, imageIndex: 0 },
    { startDate: '2025-06-05', class: classes.summer, imageIndex: 1 },
    { startDate: '2026-03-06', class: classes.autumn, imageIndex: 2 },
    { startDate: '2026-12-05', class: classes.winter, imageIndex: 3 },
    { startDate: '2027-09-05', class: classes.spring, imageIndex: 0 },
    { startDate: '2028-06-05', class: classes.summer, imageIndex: 1 },
    { startDate: '2029-03-06', class: classes.autumn, imageIndex: 2 },
    { startDate: '2029-12-05', class: classes.winter, imageIndex: 3 },
    { startDate: '2030-09-05', class: classes.spring, imageIndex: 0 },
    { startDate: '2031-06-05', class: classes.summer, imageIndex: 1 },
    // ... Add more seasons as needed
  ];
  const [mode, setMode] = useState(MODE.BEGINNER)
  useEffect(() => {
    if (mode_ == 1) {
      setMode(MODE.ADVANCED);
    }
    if (mode_ == 2) {
      setMode(MODE.INTERMEDIATE);
    }
  }, []); // Pass an empty array to only run on mount
  const [openStart, setOpenStart] = useState(false)
  const [openEnd, setOpenEnd] = useState(false)
  const [status, setStatus] = useState(STATUS.IDLE)
  
  // New state variable for submission status
  const [submissionStatus, setSubmissionStatus] = useState(null);

  const [springPriceArr, setSpringPriceArr] = useState([])
  const [summerPriceArr, setSummerPriceArr] = useState([])
  const [autumnPriceArr, setAutumnPriceArr] = useState([])
  const [winterPriceArr, setWinterPriceArr] = useState([])
  const [selectedTradeId, setSelectedTradeId] = useState(null)
  const [selectedForId, setSelectedForId] = useState(null)
  const [balanceFor, setBalanceFor] = useState([0,0,0,0])
  const [balanceTrade, setBalanceTrade] = useState([0,0,0,0])
  const [currentDate, setCurrentDate] = useState(INITIAL_DATE)
  const [backgroundClass, setBackgroundClass] = useState(classes.spring);
  const [nextBackgroundClass, setNextBackgroundClass] = useState();
  const [fade, setFade] = useState(false);

  const [nextSeasonChange, setNextSeasonChange] = useState({
    date: new Date('2022-06-05'),
    class: classes.summer,
  });
  const [currentBalance, setCurrentBalance] = useState([
    INITIAL_BALANCE,
    INITIAL_BALANCE,
    INITIAL_BALANCE,
    INITIAL_BALANCE
  ])
  const [absolutePrices, setAbsolutePrices] = useState([
    600/168,
    600/140,
    600/120,
    600/105
  ])
  const [productionRates, setProductionRates] = useState({
    spring: 168/600,
    summer: 140/600,
    autumn: 120/600,
    winter: 105/600
  })
  const [preGeneratedPrices, setPreGeneratedPrices] = useState({
    spring: [],
    summer: [],
    autumn: [],
    winter: []
  });

  const preGeneratePrices = (initialPrices, initialProductionRates, mode) => {
    let prices = {
      spring: [],
      summer: [],
      autumn: [],
      winter: []
    };
  
    let currentPrices = [...initialPrices];
    let currentProductionRates = {...initialProductionRates};
    const startDate = new Date(INITIAL_DATE);
    
    let rng = new SeedableRNG(obscuredSeed);
    let runningTime = 3650;
    //if (isTask) { runningTime = 3650 / 2; }
    for (let day = 0; day < runningTime; day++) {
      const currentDate = new Date(startDate.getTime() + day * 24 * 60 * 60 * 1000);
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth();
      const currentDay = currentDate.getDate();
  
      // Apply changes in production rates according to the date
      if ((currentYear === 2022 || currentYear === 2025 || currentYear === 2028 || currentYear === 2031) && 
          currentMonth === 5 && currentDay === 5) {
        currentProductionRates.spring /= 2;
      } else if ((currentYear === 2023 || currentYear === 2026 || currentYear === 2029) && 
                 currentMonth === 2 && currentDay === 6) {
        currentProductionRates.summer /= 2;
      } else if ((currentYear === 2023 || currentYear === 2026 || currentYear === 2029) && 
                 currentMonth === 11 && currentDay === 5) {
        currentProductionRates.autumn /= 2;
      } else if ((currentYear === 2024 || currentYear === 2027 || currentYear === 2030) && 
                 currentMonth === 8 && currentDay === 4) {
        currentProductionRates.winter /= 2;
      }
  
      const ratesArr = Object.values(currentProductionRates);
      switch(mode) {
        case MODE.BEGINNER:
          currentPrices = currentPrices.map((prevPrice, idx) => 0.99 * prevPrice + 0.01 * (1 / ratesArr[idx]) + getNormallyDistributedRandomNumber(rng, 0, 0.0002));
          break;
        case MODE.ADVANCED:
          currentPrices = currentPrices.map((prevPrice, idx) => 0.99 * prevPrice + (0.01 * (1 / ratesArr[idx]) + prevPrice * getNormallyDistributedRandomNumber(rng, 0, 0.005)));
          break;
        case MODE.INTERMEDIATE:
          currentPrices = currentPrices.map((prevPrice, idx) => 0.99 * prevPrice + 0.01 * (1 / ratesArr[idx]) + getNormallyDistributedRandomNumber(rng, 0, 0.02));
          break;
        default:
          break;
      }
  
      prices.spring.push(currentPrices[0]);
      prices.summer.push(currentPrices[1]);
      prices.autumn.push(currentPrices[2]);
      prices.winter.push(currentPrices[3]);
    }
  
    return prices;
  }

 
  const relativePrices = useMemo(() => {
    const totalPrices = absolutePrices.reduce((total, price) => total + price, 0)
    const result = absolutePrices.map((price) => price / totalPrices * 4)
    setSpringPriceArr(prev => {
      const arr = prev
      if (arr.length >= 365 * 3) {
        arr.shift()
        return arr.concat(result[0])
      } else if (arr.length === 0) {
        return arr.concat(result[0]).concat(result[0])
      } else
        return arr.concat(result[0])
    })
    setSummerPriceArr(prev => {
      const arr = prev
      if (arr.length >= 365 * 3) {
        arr.shift()
        return arr.concat(result[1])
      } else if (arr.length === 0) {
        return arr.concat(result[1]).concat(result[1]) 
      } else 
        return arr.concat(result[1])
    })
    setAutumnPriceArr(prev => {
      const arr = prev
      if (arr.length >= 365 * 3) {
        arr.shift()
        return arr.concat(result[2])
      } else if (arr.length === 0) {
        return arr.concat(result[2]).concat(result[2])  
      } else 
        return arr.concat(result[2])
    })
    setWinterPriceArr(prev => {
      const arr = prev
      if (arr.length >= 365 * 3) {
        arr.shift()
        return arr.concat(result[3])
      } else if (arr.length === 0) {
        return arr.concat(result[3]).concat(result[3])
      } else 
        return arr.concat(result[3])
    })
    return result
  }, [absolutePrices])

  const totalPrice = useMemo(() => 
    currentBalance.reduce((total, balance, i) => {
      //console.log("setting totalPrice to "+(total + balance * relativePrices[i] / 100))
      //console.log("absolute prices are "+absolutePrices[0]+" "+absolutePrices[1]+" "+absolutePrices[2]+" "+absolutePrices[3])
      return total + balance * relativePrices[i] / 100
    }, 0)
  , [relativePrices, currentBalance])

  const totalBalance = currentBalance.reduce((total, bal) => total + bal, 0)

  const handleTradeClick = useCallback(id => {
    setSelectedTradeId(id)
    setBalanceTrade(() => {
      const balance = [0,0,0,0]
      balance[id] = +currentBalance[id].toFixed(2)
      return balance
    })
  }, [currentBalance])
  
  const handleForClick = useCallback(id => {
    if (selectedTradeId !== null) {
      setSelectedForId(id)
    }
  }, [selectedTradeId])

  const handleStart = useCallback(() => {
    if (status == STATUS.ONGOING) {
      return;
    }
    setStatus(STATUS.ONGOING)
    timer.current = setInterval(() => {
      setCurrentDate((date) => {
        const newDate = date + 1000 * 3600 * 24;
        var classToUse = classes.spring;
        // Check for season change
        for (const season of SEASONS) {
          if (newDate >= new Date(season.startDate).getTime() && date <= new Date(season.startDate).getTime()) {
            classToUse = season.class;
            setImage(season.imageIndex);
          }
        }
        if (newDate < new Date(SEASONS[0].startDate).getTime()) {
            setImage(0);
        }

        return newDate;
      });
    }, A_DAY);
  }, [status]);


  const handleRestart = useCallback(() => {
    timer.current && clearInterval(timer.current)
    setStatus(STATUS.IDLE)
    setCurrentDate(INITIAL_DATE)
    setCurrentBalance([
      INITIAL_BALANCE,
      INITIAL_BALANCE,
      INITIAL_BALANCE,
      INITIAL_BALANCE
    ])
    setAbsolutePrices([600/168, 600/140, 600/120, 600/105])
    setProductionRates({
        spring: 168/600,
        summer: 140/600,
        autumn: 120/600,
        winter: 105/600
    })
    setBalanceFor([0,0,0,0])
    setBalanceTrade([0,0,0,0])
    setSelectedForId(null)
    setSelectedTradeId(null)
    setSpringPriceArr([])
    setSummerPriceArr([])
    setAutumnPriceArr([])
    setWinterPriceArr([])
    setTradeHistory([]);
    setSubmissionStatus(null); // Reset submission status on restart
  }, [])

  const handleExecute = useCallback(() => {
    if (status === STATUS.ONGOING && selectedTradeId != selectedForId) {
      // Record the trade details
      const tradeDetails = {
        sT: getSeasonAbbreviation(TOKEN_ARRAY[selectedTradeId]),
        sA: balanceTrade[selectedTradeId],
        bT: getSeasonAbbreviation(TOKEN_ARRAY[selectedForId]),
        bA: balanceFor[selectedForId],
        date: currentDate
      };
      setTradeHistory((prevTradeHistory) => [...prevTradeHistory, tradeDetails]);

      setCurrentBalance(prevBalance => {
        const balance = [...prevBalance]
        balance[selectedTradeId] = 0
        balance[selectedForId] += +balanceFor[selectedForId]
        // Ensure immutability
        return balance
      })
      setBalanceTrade([0,0,0,0])
      setBalanceFor([0,0,0,0])
      setSelectedForId(null)
      setSelectedTradeId(null)
    }
  }, [balanceFor, selectedForId, selectedTradeId, status, balanceTrade, currentDate, getSeasonAbbreviation])

  const handleCloseEnd = useCallback(() => {
    setOpenEnd(false)
    handleRestart()
  }, [handleRestart])

  const handleCloseStart = useCallback(() => {
    setOpenStart(false)
  }, [])

  const handleMode = useCallback((e, newMode) => {
    if (newMode !== null) {
      setMode(newMode)
      handleRestart()
    }
  }, [handleRestart])

  useEffect(() => {
    if (currentDate === INITIAL_DATE) {
      const prices = preGeneratePrices(absolutePrices, productionRates, mode);
      setPreGeneratedPrices(prices);
    }
  }, [currentDate, productionRates, mode]);


  useEffect(() => {
    let endDate = Date.parse('2031-09-04');
    
    const sendGameResults = (data, retries = 50) => {
      axios.post('http://simulator.seasonaltokens.org:5000/trading_results', data)
        .then((response) => {
          console.log(response.data);
          setSubmissionStatus('success'); // Update status on success
        })
        .catch((error) => {
          console.log(error);
          if (retries > 0) {
            console.log(`Retrying... attempts left: ${retries}`);
            setTimeout(() => {
              sendGameResults(data, retries - 1); // retry after 1 second
            }, 1000);
          } else {
            console.log('Failed after multiple attempts');
            setSubmissionStatus('failed'); // Update status after all retries fail
          }
        });
    };

    if (currentDate > endDate) {
      if (status === STATUS.IDLE) return;

      clearInterval(timer.current);
      setStatus(STATUS.IDLE);
      setOpenEnd(true);
      
      // Set submission status to 'sending' when starting the submission
      setSubmissionStatus('sending');

      const date = new Date();
      const dateString = date.toISOString();

      let data = {
        platform: platform,
        user_id: userid,
        score: totalPrice,
        tokens: totalBalance,
        trades: tradeHistory,
        mode: mode,
        seed: obscuredSeed,
        totalPrice: totalPrice,
        endDate: currentDate,
        contestKey: contestKey,
        dateString: dateString,
        duel: isDuel,
        code: code
      };

      sendGameResults(data); // Start sending with retry logic
    }
  }, [currentDate, userid, totalPrice, totalBalance, tradeHistory, mode, obscuredSeed, code, contestKey, platform, isTask, status]);

  

  useEffect(() => {
    const loggedStatus = localStorage.getItem("isUserLoggedIn")
    if (loggedStatus === null) {
      setOpenStart(true)
      localStorage.setItem("isUserLoggedIn", true)
    }
    return () => {
      localStorage.removeItem("isUserLoggedIn")
    }
  }, [])
  
  useEffect(() => {
    if (currentDate === INITIAL_DATE) {
      return
    }
    let day = Math.floor((currentDate - INITIAL_DATE) / (24 * 60 * 60 * 1000));
    if (day > 3649) {
        day = 3649;
    }
    setAbsolutePrices([
      preGeneratedPrices.spring[day],
      preGeneratedPrices.summer[day],
      preGeneratedPrices.autumn[day],
      preGeneratedPrices.winter[day]
    ]);
  
  }, [currentDate, preGeneratedPrices]);

  useEffect(() => {
    if (selectedForId >= 0) {
      let balanceB
      if (selectedTradeId === selectedForId) {
        balanceB = currentBalance[selectedForId]
      } else {
        const balanceA = currentBalance[selectedTradeId]
        const priceA = relativePrices[selectedTradeId]
        const priceB = relativePrices[selectedForId]
        balanceB = +(+getForBalance(balanceA, priceA, priceB).toFixed(2)*0.98).toFixed(2) // highest score wins
        //balanceB = +(+getForBalance(balanceA, priceA, priceB).toFixed(2)*1.02).toFixed(2) // lowest score wins
      }
      setBalanceFor(() => {
        const balance = [0,0,0,0]
        balance[selectedForId] = balanceB
        return balance
      })
    }
  }, [selectedTradeId ,selectedForId, relativePrices, currentBalance])


  const qrRef = useRef(null);

  const [qrCodeSize, setQrCodeSize] = useState(230);

  const handleDownloadClick = async () => {
    if (!qrRef.current || !qrRef.current.firstChild) {
      return;
    }
    // Temporarily increase the size of the QR code for the download
    setQrCodeSize(1000);

    // Need to wait for the state update to propagate and the QR code to rerender
    await new Promise(r => setTimeout(r, 1000));
 
    const canvas = qrRef.current.firstChild;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setQrCodeSize(230);
  };


  function setImage(x) {
    setCurrentIndex((prevIndex) => x); // wraps around after the 4th image
  }
  function nextImage() {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % 4); // wraps around after the 4th image
  }

  var date = new Date();
  // get the date as a string
  var dateString = date.toISOString();

  var qrText = JSON.stringify({tradeHistory, totalBalance, totalPrice, dateString, mode});
  if (qrText.length > 4000) {
    qrText = "Too much data";
  }

  return (
  <div>
    <BackgroundImageChanger activeIndex={currentIndex} />
    <div style={{ position: 'relative', zIndex: 1 }}>
    <Container className={classes.foreground}>
      <div className={classes.modebtn}>
        <ModeSwtiching
          value={mode}
          onChange={handleMode}
        />
      </div>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent='center' alignItems="center">
        <Grid item xs={6} backgroundColor="rgba(255, 255, 255, 0.85)">
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" ml={10}>
                Your tokens
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" ml={5}>
                Prices
              </Typography>
            </Grid>
          </Grid>
          <hr className={classes.hr} />
          <Grid container>
            {ICON_SVG.map((item, idx) => (
              <React.Fragment key={idx}>
                <Grid item xs={6}>
                  <LogoField
                    key={idx}
                    value={currentBalance[idx].toFixed(2)}
                    img={item}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Field key={idx} value={relativePrices[idx].toFixed(5)}/>
                    </Grid>
                    <Grid item xs={8}>
                      <Field key={idx} value='cents'/>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Charts
              spring={springPriceArr}
              summer={summerPriceArr}
              autumn={autumnPriceArr}
              winter={winterPriceArr}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 2 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent='center' alignItems="top">
        <Grid item xs={3}>
          <div className={classes.total}>
            <Typography variant="h6">Total Tokens</Typography>
            <div className={classes.totalTokens}>
              <img className={classes.seasonalLogo} src={SeasonalLogo} alt='Seasonal Token'/>
              <Typography variant='body1' ml={1}>{totalBalance.toFixed(2)}</Typography> 
            </div>
            <Typography variant="h6" mt={1}>Total value</Typography>
            <Typography variant='body1' mt={1}>${totalPrice.toFixed(2)}</Typography> 
          </div>
          <div className={classes.actions}>
            <GameAction comment="Start trading">
              <IconButton color='inherit' onClick={handleStart}>
                <StartIcon fontSize='large'/>
              </IconButton>
            </GameAction>
            <GameAction comment="Pause trading">
              <IconButton color='inherit' onClick={() => {timer.current && clearInterval(timer.current); setStatus(STATUS.IDLE)}}>
                <StopIcon fontSize='large'/>
              </IconButton>
            </GameAction>
            <GameAction comment="Restart game">
              <IconButton color='inherit' onClick={handleRestart}>
                <RestartIcon fontSize='large'/>
              </IconButton>
            </GameAction>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Grid container className={classes.tradefor}>
            <Grid item xs={6}>
              <Typography variant="h6">
                Trade
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">
                For
              </Typography>
            </Grid>
            {ICON_SVG.map((item, idx) => (
              <React.Fragment key={idx}>{/* Trade */}
                <Grid item xs={6}>
                  <LogoFieldClickable
                    key={idx}
                    classClicked={idx === selectedTradeId ? classes.img : classes.unselectedImg}
                    value={balanceTrade[idx]}
                    img={item}
                    onClick={() => handleTradeClick(idx)}
                  />
                </Grid>{/* For */}
                <Grid item xs={6}>
                  <LogoFieldClickable
                    key={idx}
                    classClicked={idx === selectedForId ? classes.img : classes.unselectedImg}
                    value={balanceFor[idx]}
                    img={item}
                    onClick={() => handleForClick(idx)}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.date}>
            <Typography variant="h6">
              &nbsp;Date
            </Typography>
            <div className={classes.marginLeft20}>
              <Typography
                variant='body1'
              >
                {new Date(currentDate).toISOString().slice(0, 10)}
              </Typography>
            </div>
          </div>
          <div className={classes.executeBox}>
            <div className={classes.padding15}>
              <Typography variant="p">
                Trade {balanceTrade[selectedTradeId] || 0} {TOKEN_ARRAY[selectedTradeId]} tokens for {balanceFor[selectedForId] || 0} {TOKEN_ARRAY[selectedForId]} tokens
              </Typography>
            </div>
            <Button variant='contained' onClick={handleExecute}>Execute Trade</Button>
          </div>
        </Grid>
      </Grid>
      <TransitionModal
        open={openEnd}
        onClose={handleCloseEnd}
        content={
          <>
            <Typography>
              Trading game ended. You earned {totalBalance.toFixed(
                2
              )} tokens worth a total of ${totalPrice.toFixed(
                2
              )}. Please click Restart game button to restart. Click the QR code below to download a higher-resolution version.
            </Typography>
            <div ref={qrRef} onClick={handleDownloadClick} style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <QRCode value={qrText} size={qrCodeSize} />
            </div>

            {/* New Submission Status Indicator */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Typography>
                {submissionStatus === 'success' && (
                  <span style={{ color: 'green' }}>✅ Results successfully sent to the server.</span>
                )}
                {submissionStatus === 'sending' && (
                  <span style={{ color: 'orange' }}>⏳ Sending results to the server...</span>
                )}
                {submissionStatus === 'failed' && (
                  <span style={{ color: 'red' }}>❌ Failed to send results. Please use the QR code to submit your score manually.</span>
                )}
              </Typography>
            </div>

            {/* Existing Mode and Time Information */}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Typography>
                Mode: {mode === 0 ? 'Beginner' : (mode === 1 ? 'Advanced' : 'Intermediate')}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Typography>
                {dateString}
              </Typography>
            </div>
          </>
        }
      />
      <TransitionModal
        open={openStart}
        onClose={handleCloseStart}
      >
        <Typography>
          <b>The Scenario:</b>
        </Typography>
        <br/>
        <Typography>
          Hello {username}. {isTask == 1 ? "Welcome to the trading simulator task. The simulation lasts for five years, during which you can trade Seasonal Tokens to gain more tokens over time." : "You have ten years to achieve financial security."} You start off with 1000 Seasonal Tokens of each type. Now your task is to get as many tokens as you can in the next {isTask == 1 ? "five" : "ten"} years. You have a rule to guide you: Trade tokens for more tokens.
        </Typography>
        <br/>
        <Typography>
          <b>How to Play:</b>
        </Typography>
        <br/>
        <div className={classes.flex}>
          <Typography mr={2}> 1. Start trading </Typography>
          <StartIcon/>
        </div>
        <div className={classes.flex}>
          <Typography mr={2}> 2. Select the token you want to trade:  </Typography>
          <img width='25' src={ICON_SVG[0]} alt='spring'/>
        </div>
        <div className={classes.flex}>
          <Typography mr={2}> 3. Select the token you want to trade it for: </Typography>
          <img width='25' src={ICON_SVG[1]} alt='summer'/>
        </div>
        <Typography> 4. Execute the trade </Typography>
      </TransitionModal>
    </Container>
    </div>
  </div>
  );
}

export default App;
