export const getForBalance = (balanceA, priceA, priceB) => {
  return balanceA * priceA / priceB 
}

export const formatDate = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join(' / ')
}

export class SeedableRNG {
  constructor(seed = 123456789) {
    this.seed = seed;
  }

  nextFloat() {
    // Using a Lehmer (Park-Miller) LCG (Linear congruential generator)
    this.seed = this.seed * 48271 % 2147483647;
    return (this.seed - 1) / 2147483646;
  }
}

const boxMullerTransform = (rng) => {
  let u = 0, v = 0;

  while(u === 0) u = rng.nextFloat(); //Converting [0,1) to (0,1)
  while(v === 0) v = rng.nextFloat();
  
  let u1 = Math.sqrt(-2.0 * Math.log(u));
  let v1 = 2.0 * Math.PI * v;
  return {
    z0: u1 * Math.sin(v1),
    z1: u1 * Math.cos(v1)
  };
}

export const getNormallyDistributedRandomNumber = (rng, mean, stddev) => {
  const { z0 } = boxMullerTransform(rng);
  return z0 * stddev + mean;
}
